.headerConteiner {
  display: flex;
  background-color: #f5f7fa;
  justify-content: flex-end;
  height: 56px !important;

  .left {
    display: flex;
  }
  .region {
    margin-left: 20px;
  }
}
.title {
  color: white;
  font-size: 1.6rem;
  font-weight: bold;
}
.rightItem {
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 0;
  .photo {
    width: 24px;
    height: 24px;
    border-radius: 50%;
    overflow: hidden;
    img,
    svg {
      display: block;
      width: 100%;
      height: 100%;
      border: none;
      cursor: pointer;
    }
  }
}

.userPopover {
  margin: 0;
  padding: 0;
  .userPopoverItem {
    &:hover {
      cursor: pointer;
      color: #aaa;
    }
  }
}
.userPopover:hover,
.userEmail:hover {
  cursor: pointer;
}
