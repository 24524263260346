@import '~antd/dist/antd.css';

.ant-modal-root .ant-modal-close {
  outline: none;
}

.ant-message-custom-content {
  display: flex;
  align-items: center;
}

.table-picker {
  &-footer {
    display: flex;
    justify-content: flex-end;
    padding: 10px 20px;
    border-top: 1px solid rgb(228, 228, 231);
  }
}

.normal-table {
  .ant-table {
    padding: 20px 20px 0;
  }
}

.ant-upload-text-icon,
.ant-upload-list-item-card-actions {
  display: flex;
}

.ant-tabs .ant-tabs-content {
  height: 100%;
}

.hidden-scrollbar {
  &::-webkit-scrollbar {
    display: none;
  }
}

.ant-upload-list-picture .ant-upload-list-item-thumbnail,
.ant-upload-list-picture-card .ant-upload-list-item-thumbnail {
  opacity: 1;
}

.tab-table.pro-table .ant-table {
  padding: 0;
}

// mock 修改antd自带的switch组件样式，防止checked状态样式错误
.ant-switch-checked .ant-switch-handle {
  left: calc(60%) !important;
}

.label-cascader .ant-cascader-menu {
  max-width: 320px;
}

.ant-input-number,
.ant-input-affix-wrapper,
.ant-select-selector,
.ant-picker-range {
  border: 0.5px solid #dde1eb;
}
.ant-input-affix-wrapper:hover {
  box-shadow: none !important;
  border: 0.75px solid #bcc1cc;
}

.ant-input-affix-wrapper-focus,
.ant-input-affix-wrapper-focused,
.ant-input-number-focused {
  box-shadow: none !important;
  border: 1px solid #1a68ff !important;
}

.ant-picker-range:hover {
  border: 0.75px solid #bcc1cc;
}
.ant-picker-focused {
  border: 1px solid #1a68ff !important;
}
.ant-picker-range .ant-picker-active-bar {
  background-color: #1a68ff !important;
}
.ant-select:not(.ant-select-disabled):hover .ant-select-selector {
  border: 0.75px solid #bcc1cc;
  box-shadow: none;
}
.ant-select-focused:not(.ant-select-disabled) .ant-select-selector,
.ant-select-open .ant-select-selector {
  border: 1px solid #1a68ff !important;
  box-shadow: none !important;
}
.ant-select-dropdown {
  padding: 8px 0px;
  background: #ffffff;
  border: 0.5px solid #dde1eb;
  box-sizing: border-box;
  box-shadow: 0px 4px 10px rgba(28, 32, 41, 0.08);
  border-radius: 8px;
}

.ant-select-item {
  padding: 9px 0px 9px 12px;
  height: 40px;
  line-height: 22px;
  background-color: #fff;
  color: #5d677a;
  font-size: 14px;
  &-option {
    &-selected:not(&-disabled) {
      color: #1a68ff;
      background-color: #fff !important;
    }
  }
  &-option:hover {
    background-color: #f5f7fa;
  }
}

.ant-picker-focused {
  border: 0.75px solid #bcc1cc;
  box-shadow: none;
}

.tab-container {
  background-color: #fff;
  border-radius: 12px;
  padding: 0 24px;
  .ant-tabs-nav {
    height: 48px;
    margin-bottom: 0;
    .ant-tabs-tab {
      margin-right: 40px;
      margin-left: 0px;
      color: #5d677a !important;
    }
    .ant-tabs-tab.ant-tabs-tab-active .ant-tabs-tab-btn {
      color: #1c2029 !important;
      font-weight: 500 !important;
    }
    .ant-tabs-ink-bar {
      border-bottom: 3px solid #1a68ff;
      border-radius: 2px;
    }
  }
}

.ant-popover-inner,
.ant-tooltip-inner {
  background: #ffffff;
  border: 0.5px solid #dde1eb;
  box-sizing: border-box;
  box-shadow: 0px 4px 10px rgba(28, 32, 41, 0.08);
  border-radius: 8px;
  color: #1c2029;
}
.ant-popover-arrow,
.ant-tooltip-arrow {
  display: none;
}
