.contentContainer {
  padding: 8px 24px 0 24px;
  height: calc(~'100vh - 56px');
  overflow-y: auto;
  background-color: #f5f7fa;
}

.regionText {
  font-size: 26px;
  font-weight: 600;
}
