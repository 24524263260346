.container {
  display: flex;
  width: 100%;
  min-height: 32px;
  justify-content: center;
  align-items: center;
  padding: 0 20px;
  margin: 0 20px;
  border: 1px solid #dddddd;
  background-color: #fff;
  .content {
    font-weight: bold;
  }
  a {
    color: #1890ff !important;
  }
}
:global {
  .layout-container__header__widget {
    min-height: 32px;
    display: flex;
    justify-content: end;
    align-items: center;
  }
}
