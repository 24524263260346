.platform-title-area {
  padding-top: 16px;
  padding-bottom: 24px;
  border-bottom: 1px solid #ebeef5;
  font-size: 16px;
  color: #1c2029;
  font-weight: bold;
}
.platform-title {
  margin-left: 24px;
  text-align: center;
}
:global {
  .platform-region {
    margin-top: 10px;
    text-align: center;
    .ant-btn {
      width: 192px;
      height: 40px;
      border-color: #dde1eb;
      border-radius: 8px;
      box-shadow: none;
      color: #9096a3;
    }
  }
  .custom-menu-contaner {
    padding-top: 4px;
    padding-left: 8px;
    padding-right: 8px;
    &.ant-menu-inline,
    .ant-menu-vertical,
    .ant-menu-vertical-left {
      border-right: none;
    }
    .ant-menu-item {
      &:after {
        border-right-width: 0;
      }
      &:hover {
        background: rgba(64, 102, 178, 0.04);
      }
    }
    .ant-menu-item-selected {
      border-radius: 8px;
      color: #1a68ff;
    }
    .ant-menu-submenu-selected {
      .ant-menu-submenu-title {
        color: #1a68ff;
      }
    }
    [role='menuitem'] {
      padding-left: 16px !important;
    }
    .ant-menu-submenu {
      .ant-menu-sub {
        background: #fff !important;
        [role='menuitem'] {
          padding-left: 42px !important;
        }
        // .ant-menu-item-selected {
        //     border-radius: 0;
        // }
      }
    }
    .ant-menu-item-icon > svg {
      width: 16px !important;
      height: 16px !important;
    }
  }
  .ant-menu-light {
    .ant-menu-submenu-title {
      &:hover {
        color: #1a68ff;
      }
    }
    .ant-menu-submenu-active {
      color: #1a68ff;
    }
    .ant-menu-item {
      &:hover {
        color: #1a68ff;
        border-radius: 8px;
      }
    }
  }
  .ant-menu:not(.ant-menu-horizontal) .ant-menu-item-selected {
    background: rgba(64, 102, 178, 0.04);
  }
  .ant-menu-sub.ant-menu-inline {
    background: rgba(64, 102, 178, 0.04) !important;
  }
}
